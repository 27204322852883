import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as MailIcon } from '../../../assets/icons/brands/mail.svg';
import { ReactComponent as GithubIcon } from '../../../assets/icons/brands/github.svg';
import { ReactComponent as LinkedInIcon } from '../../../assets/icons/brands/linkedin.svg';
import { ReactComponent as ResumeIcon } from '../../../assets/icons/brands/resume.svg';

export const SHARE_LINKS_DATA = {
    mail: {
        icon: MailIcon,
        tooltipTranslation: (
            <FormattedMessage
                id="Footer.shareLinks.EmailOnTooltip"
                defaultMessage="Email me on"
                values={{ platform: 'Mail' }}
            />
        ),
        getLink: () => 'mailto:ddlogesh@gmail.com'
    },
    github: {
        icon: GithubIcon,
        tooltipTranslation: (
            <FormattedMessage
                id="Footer.shareLinks.shareOnTooltip.github"
                defaultMessage="Code with me on {platform}"
                values={{ platform: 'Github' }}
            />
        ),
        getLink: () => 'https://github.com/ddlogesh'
    },
    linkedIn: {
        icon: LinkedInIcon,
        tooltipTranslation: (
            <FormattedMessage
                id="Footer.shareLinks.shareOnTooltip.linkedIn"
                defaultMessage="Connect with me on {platform}"
                values={{ platform: 'LinkedIn' }}
            />
        ),
        getLink: () => 'https://www.linkedin.com/in/logesh-dinakaran/'
    },
    resume: {
        icon: ResumeIcon,
        tooltipTranslation: (
            <FormattedMessage
                id="Footer.shareLinks.resumeOnTooltip"
                defaultMessage="Share on {platform}"
                values={{ platform: 'Resume' }}
            />
        ),
        getLink: () => 'https://drive.google.com/uc?export=download&id=1iSUpbvo1qisgQCHVrzE5fIxx5FJvUTYm'
    }
};
